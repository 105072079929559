// Links
export const HOSPITAL_PROFILE_HELP_LINK =
  "https://repsignal-s2nhealth.zendesk.com/hc/en-us/articles/19328371197083-RepSignal-Hospital-Profile";
export const PHYSICIAN_PROFILE_HELP_LINK =
  "https://repsignal-s2nhealth.zendesk.com/hc/en-us/articles/19328444747419-RepSignal-Physician-Profile";
export const GENERAL_REPSIGNAL_HELP_LINK =
  "https://repsignal-s2nhealth.zendesk.com/hc/en-us";
export const PROCEDURE_MODULE_HELP_LINK =
  "https://repsignal-s2nhealth.zendesk.com/hc/en-us/articles/19310850546331-Procedure-Modules";
export const TERRITORY_PROFILE_HELP_LINK =
  "https://repsignal-s2nhealth.zendesk.com/hc/en-us/articles/20057143016987-RepSignal-Territory-Profile";
export const RECOMMENDED_HOSPITALS_AND_PHYSICIANS_HELP_LINK =
  "https://repsignal-s2nhealth.zendesk.com/hc/en-us/articles/20075118762395-What-are-RepSignal-Recommended-Hospitals-and-RepSignal-Recommended-Physicians-";
export const REGION_PROFILE_HELP_LINK =
  "https://repsignal-s2nhealth.zendesk.com/hc/en-us/articles/20057161375515-RepSignal-Region-Profile";

// Popovers
export const PROCEDURE_VOLUME_ESTIMATES_TOOLTIP = {
  link: "https://repsignal-s2nhealth.zendesk.com/hc/en-us/articles/19328242374427-RepSignal-Procedure-Volume-Estimates",
  content:
    "RepSignal estimated annual procedure volumes for the past 4 completed quarters. Estimates represent all payors and all settings of care.",
};
export const INDUSTRY_PAYMENTS_TOOLTIP = {
  link: "https://repsignal-s2nhealth.zendesk.com/hc/en-us/articles/20070433938971-What-are-Industry-Payments-",
  content: "Source: Open Payments. For more information read here",
};
export const ORGANIZATION_PROFILE_TOOLTIP = {
  link: "https://repsignal-s2nhealth.zendesk.com/hc/en-us/articles/19926878200347-RepSignal-Organization-Profile",
  content: "Source: CMS Open Payments",
};
