<template>
  <nav
    class="navbar navbar-expand-lg navbar-light fixed-top bg-light border-0 shadow-sm"
  >
    <div class="container-fluid">
      <router-link :to="{ name: 'home' }" class="navbar-brand"
        ><img src="@/assets/RepSignalLogo.png" height="43" alt=""
      /></router-link>
      <ProcedureModuleSwitcher
        v-if="procMods.length != 0"
        :procMods="procMods"
        @module-switch="(procModId) => $emit('module-switch', procModId)"
      />
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse"
        aria-controls="navbarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <TutorialPopover
            feature="navUpdates"
            header="Navigation updates"
            :showIfNeverDismissed="!isMobileDevice"
            @dismiss="checkMarketSummaryTutorialPopover"
            :anchorStyle="{
              // Resets to background color of Nav Bar
              backgroundColor: 'rgb(248, 249, 250)',
            }"
          >
            <template #anchor>
              <div class="navbar-nav">
                <li class="nav-item">
                  <router-link :to="{ name: 'home' }" class="nav-link">
                    Home
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link :to="{ name: 'explorer' }" class="nav-link">
                    Explorer
                  </router-link>
                </li>
                <li v-if="isLeader" class="nav-item">
                  <router-link
                    :to="{ name: 'region_manager' }"
                    class="nav-link"
                  >
                    Territories
                  </router-link>
                </li>

                <TutorialPopover
                  v-if="isLeader && hasMarketSummaryAccess"
                  feature="marketSummary"
                  header="Introducing MarketSignal"
                  :showIfNeverDismissed="
                    showMarketSummaryPopover && !isMobileDevice
                  "
                  :anchorStyle="{
                    // Resets to background color of Nav Bar
                    backgroundColor: 'rgb(248, 249, 250)',
                  }"
                >
                  <template #anchor>
                    <li class="nav-item">
                      <router-link
                        :to="{ name: 'marketSummary' }"
                        class="nav-link"
                      >
                        Markets
                        <span class="badge beta-badge">BETA</span>
                      </router-link>
                    </li>
                  </template>
                  <template #content>
                    <div style="max-width: 500px">
                      Become an expert in your clinical market with insights
                      into total procedures, trends, payor mix, and more!
                      <img
                        src="@/assets/marketSummary.png"
                        alt="Screenshot of MarketSignal"
                        class="mt-3"
                        style="width: 100%"
                      />
                    </div>
                  </template>
                  <template #footer="{ dismiss }">
                    <div class="d-flex justify-content-end">
                      <button
                        class="btn main-button-inverse me-2"
                        @click="dismiss"
                      >
                        Dismiss
                      </button>
                      <router-link
                        :to="{ name: 'marketSummary' }"
                        @click="dismiss"
                      >
                        <button class="btn main-button-inverse">
                          Let's Go!
                        </button>
                      </router-link>
                    </div>
                  </template>
                </TutorialPopover>

                <li class="nav-item">
                  <router-link :to="{ name: 'lists' }" class="nav-link">
                    My Lists
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link :to="{ name: 'newsfeed' }" class="nav-link">
                    My News
                    <span v-if="news.showIndicator"
                      ><i
                        class="pi pi-circle-on align-text-top"
                        style="font-size: 0.55rem; color: #31bfd8"
                      ></i>
                    </span>
                  </router-link>
                </li>
              </div>
            </template>
            <template #content>
              <div style="max-width: 500px">
                We have made some changes to our navigation bar. Market Explorer
                is now <strong>Explorer</strong>, and Manage Regions is now
                <strong>Territories</strong>.
              </div>
            </template>
          </TutorialPopover>
          <li class="nav-item">
            <BaseModalButton
              class="btn nav-link position-relative notes-button"
              title="Intel Book"
              modalId="notesModal"
            >
              <i class="pi pi-book text-black" />
              <span
                v-show="unreadNotes.length !== 0"
                class="notification position-absolute top-25 start-75 translate-middle border border-light rounded-circle bg-info"
                title="New notes have been shared with you"
              >
                <span class="visually-hidden">new notes</span>
              </span>
            </BaseModalButton>
          </li>
          <li class="nav-item animate">
            <router-link :to="{ name: 'search' }" class="nav-link"
              ><i class="pi pi-search"></i
            ></router-link>
          </li>
          <li class="nav-item animate">
            <a
              class="nav-link"
              :href="GENERAL_REPSIGNAL_HELP_LINK"
              target="_blank"
              ><i class="pi pi-question-circle"></i
            ></a>
          </li>
          <li class="nav-item animate nav-li dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              data-cy="NavCogDropdown"
            >
              <i class="pi pi-cog"></i>
            </a>
            <ul class="dropdown-menu dropdown-menu-end">
              <li>
                <router-link
                  :to="{ name: 'account' }"
                  class="nav-link text-dark"
                  >My Account</router-link
                >
              </li>
              <li class="nav-item" v-if="user.is_admin">
                <a class="nav-link" :href="getEnvUrl + '/admin/login'">
                  Admin
                </a>
              </li>
              <li>
                <router-link
                  :to="{ name: 'logout' }"
                  class="nav-link text-dark"
                  data-cy="NavLogoutButton"
                  >Logout</router-link
                >
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { useLoggedInUserStore } from "@/store/loggedInUser";
import { useNewsStore } from "@/store/news";
import ProcedureModuleSwitcher from "./ProcedureModuleSwitcher.vue";
import BaseModalButton from "@/components/buttons/BaseModalButton.vue";
import TutorialPopover from "@/components/ui/TutorialPopover.vue";
import isMobile from "@/composables/datatable/isMobile";
import { GENERAL_REPSIGNAL_HELP_LINK } from "@/services/infoTooltips";

export default {
  components: {
    ProcedureModuleSwitcher,
    BaseModalButton,
    TutorialPopover,
  },
  props: {
    procMods: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["module-switch"],
  setup() {
    const user = useLoggedInUserStore();
    const news = useNewsStore();
    const { isMobileDevice } = isMobile("lg");
    return { user, news, isMobileDevice, GENERAL_REPSIGNAL_HELP_LINK };
  },
  data() {
    return {
      hasMarketSummaryAccess: false,
      showMarketSummaryPopover: false,
    };
  },
  computed: {
    getEnvUrl() {
      return import.meta.env.VITE_FLASK_URL;
    },
    unreadNotes() {
      return this.user.notes.filter((note) => !note.has_been_read);
    },
    isLeader() {
      return this.user.isLeader;
    },
  },
  methods: {
    checkMarketSummaryTutorialPopover() {
      // Show market summary popover if:
      // 1. `marketSummary` feature flag is enabled
      // 2. `navUpdates` tutorial popover has been dismissed
      // 3. `marketSummary` tutorial popover has not been dismissed
      this.showMarketSummaryPopover =
        this.hasMarketSummaryAccess &&
        localStorage.getItem("navUpdates") === "true" &&
        localStorage.getItem("marketSummary") !== "true";
    },
  },
  async created() {
    this.hasMarketSummaryAccess = await this.user.getFeatureFlag(
      "marketSummary"
    );
    this.checkMarketSummaryTutorialPopover();
  },
};
</script>

<style scoped>
a {
  color: black !important;
}
.notification {
  padding: 0.4em;
}
.router-link-exact-active {
  font-weight: 600;
}
.notes-button {
  border: none;
  color: black;
  transition: color 0.15s;
}
.notes-button:focus {
  box-shadow: none;
}
.notes-button i {
  transition: color 0.15s;
}
.notes-button:hover i {
  color: #124e70 !important;
}
.main-button-inverse {
  --bs-btn-font-size: 0.875rem;
}
</style>
